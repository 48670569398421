<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-table :data="list">
        <el-table-column prop="id" label="编号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="title" label="标题" show-overflow-tooltip></el-table-column>
        <el-table-column label="缩略图" show-overflow-tooltip>
          <template v-slot="s">
            <img :src="s.row.pic | tomedia" style="width: 3rem;height: 3rem;border-radius: .5rem">
          </template>
        </el-table-column>
        <el-table-column label="视频" show-overflow-tooltip>
          <template #default="s">
            <div>视频文件：
              <a v-if="s.row.file" :href="s.row.file | tomedia" target="_blank">点击预览</a>
              <span v-else>未配置</span>
            </div>
            <div>
              视频号视频ID：{{s.row.mp_video_id ? s.row.mp_video_id : "未配置"}}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="关联商家" show-overflow-tooltip>
          <template #default="s">
            ID:{{s.row.merchant_id}}-{{s.row.merchant_title}}
          </template>
        </el-table-column>
        <el-table-column label="关联商品" show-overflow-tooltip>
          <template #default="s">
            <span v-if="s.row.goods_id > 0"> ID:{{s.row.goods_id}}-{{s.row.goods_title}}</span>
          </template>
        </el-table-column>
        <el-table-column label="关联卡券" show-overflow-tooltip>
          <template #default="s">
            <span v-if="s.row.coupon_id > 0"> ID:{{s.row.coupon_id}}-{{s.row.coupon_title}}</span>
          </template>
        </el-table-column>
        <el-table-column label="关联展示模式" show-overflow-tooltip>
          <template #default="s">
            {{s.row.about_mode == 0 ? "商品" : "卡券"}}
          </template>
        </el-table-column>
        <el-table-column label="操作" show-overflow-tooltip>
          <template slot="header">
            <el-button @click="editBox = true">添加</el-button>
          </template>
          <template v-slot="s">
            <el-button @click="edit(s.row)" type="primary">编辑</el-button>
            <el-button @click="del(s.row)" type="danger">删除</el-button>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
    <el-dialog destroy-on-close title="编辑" :visible.sync="editBox" @close="editClose">
      <el-form label-width="6rem" style="padding: 0 2rem">
        <el-form-item label="关联商家">
          <ChooseMerchant width-input v-model="editForm.merchant_id"></ChooseMerchant>
        </el-form-item>
        <el-form-item label="关联商品">
          <y_choose_goods v-model="editForm.goods_id" width-input></y_choose_goods>
        </el-form-item>
        <el-form-item label="关联卡券">
          <coupon-choose-with-merchant
              v-model="editForm.coupon_id"
              v-if="editForm.merchant_id"
              :merchant-id="editForm.merchant_id"
          ></coupon-choose-with-merchant>
          <span v-else>请先选择关联商家</span>
        </el-form-item>
        <el-form-item label="关联展示模式">
          <el-radio-group v-model="editForm.about_mode">
            <el-radio :label="0">商品</el-radio>
            <el-radio :label="1">卡券</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="标题">
          <el-input v-model="editForm.title"></el-input>
        </el-form-item>
        <el-form-item label="缩略图">
          <y_upload_img v-model="editForm.pic"></y_upload_img>
        </el-form-item>
        <el-form-item label="视频文件">
          <y_upload_video v-model="editForm.file"></y_upload_video>
        </el-form-item>
        <el-form-item label="视频号视频ID">
          <el-input v-model="editForm.mp_video_id"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editBox = false">取 消</el-button>
        <el-button type="primary" @click="editSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import y_upload_img from '@/components/y-form/y-upload/y_upload_img'
import y_upload_video from "@/components/y-form/y-upload/y_upload_video";
import y_choose_goods from "@/components/shop/y_choose_goods";
import ChooseMerchant from "@/components/card/Merchant/ChooseMerchant";
import CouponChooseWithMerchant from "@/components/card/CouponChoose/CouponChooseWithMerchant.vue";

const editFormTpl = {
  merchant_id: 0,
  goods_id: 0,
  pic: "",
  file: "",
  mp_video_id: "",
  title:"",
  coupon_id:0,
  about_mode:0,
}

export default {
  name: "video",
  components: {
    CouponChooseWithMerchant,
    ChooseMerchant,
    y_choose_goods,
    y_upload_video,
    y_upload_img,
  },
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      editBox: false,
      editForm: {...editFormTpl}
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    del({id}) {
      this.$u.api.card.plugin.homePage.videoPage.videoDel({id}).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
    edit(item) {
      this.editForm = {...item};
      this.editBox = true;
    },
    editClose() {
      this.editForm = {...editFormTpl}
      this.editForm.merchant_id = 0;
      this.editForm.goods_id = 0;
      this.editForm.coup = 0;
    },
    pageChange(e){
      this.page = e;
      this.load();
    },
    load() {
      this.$u.api.card.plugin.homePage.videoPage.videoSearch({
        page:this.page,
      }).then(res => {
        this.list = res.list;
        this.total = res.total;
      })
    },
    editSubmit() {
      this.$u.api.card.plugin.homePage.videoPage.videoEdit(this.editForm).then(() => {
        this.$message.success("操作成功");
        this.editBox = false;
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>